import React from "react"


export default function InfoSection({textHeading, textSubtitle, ...args}) {
  return (
    <div className={`bg-caffeinity-brown py-16 relative lg:grid lg:grid-cols-2 lg:gap-x-8 text-center px-4 ${args.className}`}>
      <div className="lg:col-span-1">
        <h2 className="font-extrabold tracking-tight">
          {textHeading}
        </h2>
      </div>
      <h4 className="font-bold tracking-normal lg:max-w-lg mt-6 sm:mt-0 text-justify">{textSubtitle}
      </h4>
    </div>
  )
}