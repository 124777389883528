import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function CtaGetCoffee() {
  return (
    <div className="relative bg-caffeinity-brown">
      <div className="mt-44 py-24 bg-caffeinity-brown">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div className="mt-6">
              <h2 className="font-extrabold tracking-tight">
                Darf es noch Kaffee dazu sein..?
              </h2>
              <p className="mt-4 text-lg">
                Hast du noch nicht den richtigen Kaffee für deinen Geschmack gefunden, oder möchtest du einfach etwas Abwechslung beim Kaffee? Probier unverbindlich und ohne Risiko unser Caffeinity 1-Monats Abonnement aus.
              </p>
              <div className="mt-6">
              <Link
                to="/taste"
            className="btn-primary"
          >
                  Geschmacksprofil jetzt erstellen
                </Link>
              </div>
            </div>
          </div>

          <div className="py-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="lg:pl-4 lg:m-0 lg:relative">

              <StaticImage src="../images/caleb-minear-kVAs4SVIxSk-unsplash.jpg"
                           alt="Caffeinity speciality coffee sommelier"
                           imgClassName="w-full h-full object-cover"
                           className="mx-auto"

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
