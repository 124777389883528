import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import InfoSection from "../../components/infoSection"
import CtaGetCoffee from "../../components/ctaGetCoffee"
import { Link } from "gatsby"

const features = [
  {
    name: "1 Kaffee abwiegen und frisch mahlen",
    description:
      " Für das Caffeinity-Rezept benötigen wir 15 Gramm Kaffee. Umso genauer du arbeitest, desto besser. Deswegen empfehlen wir eine Waage zu verwenden. Somit kannst du sehr präzise arbeiten.",
    imageSrc: "/brewguide/filter/filter_1.jpeg",
    imageAlt: ""
  },
  {
    name: "2 Filter & Kanne",
    description:
      "Papierfilter seitlich falten und in V60 geben. Anschließend auf ein Gefäß oder eine Tasse stellen.",
    imageSrc: "/brewguide/filter/filter_2.JPG",
    imageAlt: ""
  },
  {
    name: "3 Filter spühlen",
    description:
      "Papierfilter und Filter mit kochend heißem Wasser spülen. Hierdurch wird der Filter und das Gefäß vorgewärmt und Papiergeschmack aus dem Filter geschwemmt. Danach das Gefäß entleeren und wieder unter den V60-Filter stellen.",
    imageSrc: "/brewguide/filter/filter_3.JPG",
    imageAlt: ""
  },
  {
    name: "4 Kaffeemehl",
    description:
      "Kaffeemehl in den Filter geben und mit dem Finger ein kleines Loch in der Mitte drücken. Hier soll sich beim ersten eingießen (Blooming) das Wasser sammeln, damit der gesamte Kaffee befeuchtet wird.",
    imageSrc: "/brewguide/filter/filter_4.jpeg",
    imageAlt: "Black canvas body with chrome zipper and key ring."
  },
  {
    name: "5 Vorbereiten zum brühen",
    description:
      "Gefäß mit dem Filter auf eine Waage stellen und diese tarieren. Lege eine Stoppuhr oder ein Handy bereit, um die Zeit zu messen.",
    imageSrc: "/brewguide/filter/filter_5.jpeg",
    imageAlt: ""
  },
  {
    name: "6 Phase 1 - Blooming",
    description:
      "Starte den Timer und gieße langsam 30ml (bis maximal 45ml) Wasser direkt auf den Kaffee und warte bis insgesamt 45 Sekunden um sind. Achte hierbei darauf, dass der gesamte Kaffee benetzt wird. In der Blooming-Phase geben wir dem Kaffee die Zeit, dass das CO2 austreten kann (Blasen).",
    imageSrc: "/brewguide/filter/filter_6.jpeg",
    imageAlt: ""
  },
  {
    name: "7 Phase 2 - Aufguss",
    description:
      "Diese Phase dauert von 0:45 bis 1:45. Hier wird langsam und gleichmäßig Wasser in kreisrunden Bewegungen eingegossen bis insgesamt 160 ml Wasser erreicht sind.",
    imageSrc: "/brewguide/filter/filter_7.jpeg",
    imageAlt: ""
  },
  {
    name: "8 Phase 3 - 2ter Aufguss",
    description:
      "Ab Minute 1:45 gießen wir die restliche Menge, in langsamen gleichmäßigen und kreisrunden Bewegungen, auf bis insgesamt 250 ml erreicht sind.",
    imageSrc: "/brewguide/filter/filter_8.jpeg",
    imageAlt: ""
  },
  {
    name: "9 Umrühren",
    description:
      "Um sicherzustellen, dass der Kaffee gleichmäßig verteilt ist, mit einem Löffel einmal linksherum und einmal rechtsherum rühren.",
    imageSrc: "/brewguide/filter/filter_9.jpeg",
    imageAlt: ""
  },
  {
    name: "10 Genießen",
    description:
      "Nach insgesamt 3 Minuten sollte das gesamte Wasser durch den Filter gelaufen sein. Anschließend den Papierfilter mit dem Kaffeemehl entsorgen (oder kompostieren) und den Kaffee genießen.",
    imageSrc: "/brewguide/filter/filter_10.jpeg",
    imageAlt: ""
  }
]

const tippsntricks = [
  {
    title: "Genauigkeit",
    description: "Um konstant guten Kaffee zu erhalten muss genau gearbeitet werden. Wir empfehlen es die gemahlene Kaffeemenge immer abzuwiegen um sicher zu gehen, dass sie gleich ist. Hierbei wird der Siebträger auf die Waage gestellt und tariert. Danach wird das Kaffeemehl gemahlen und der Siebträger mit dem Kaffeemehl erneut gewogen."
  },
  {
    title: "Veränderung des Rezepts",
    description: "Um wirklich einen Unterschied im Espresso zu erkennen, sollte immer nur EIN Parameter (Menge, Mahlgrad, Bezugszeit, Temperatur, Druckprofil) bei der Zubereitung verändert werden. \n" +
      "\n" +
      "Nur so kann ein wirklicher Unterschied zum vorherigen shot hergestellt werden und bewertet werden ob die Veränderung zu einer Verbesserung oder Verschlechterung geführt hat."
  }, {
    title: "Mahlgrad",
    description: "Der Mahlgrad für Espresso ist allgemein sehr fein. \n" +
      "\n" +
      "Hellere Rötungen können feiner gemahlen werden, da die Bohnen nicht unkontrolliert splittern.\n" +
      "\n" +
      "Dunklere Rötungen sollten größer, aber immer noch fein, gemahlen werden"
  },
  {
    title: "Wassertemperatur",
    description: "Temperatur zwischen 88 und 96 Grad\n" +
      "\n" +
      "Helle Rötungen können heißer gebrüht werden\n" +
      "\n" +
      "Dunklere Röstungen sollten “kälter” gebrüht werden"
  }
]

const SiebtraegerPage = () => (
  <Layout>
    <Seo title="Brew Guide" />

    <div className="bg-white">
      <div aria-hidden="true" className="relative">

        <StaticImage
          className="w-full h-96 object-center object-cover"
          src="../../images/brewguide/charlie-firth-4LbLyYESbFE-unsplash.jpg"
          alt="brew guide filter v60 chemex von caffeinity"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />
      </div>

    </div>


    <div>
      <div className="max-w-7xl mx-auto py-24 sm:py-32 sm:px-2 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="max-w-6xl text-center">
            <p className="mt-2 text-6xl font-extrabold tracking-tight sm:text-4xl text-caffeinity-green-dark">Filter</p>
            <p className="mt-4 text-xl text-gray-600 text-justify">
              In den folgenden Schritten haben wir dir eine Schritt für Schritt Anleitung mit Bildern zusammengestellt,
              mit der du ganz einfach eine sehr gute Tasse Kaffee brühen kannst.
            </p>
          </div>

          <div className="space-y-16 pt-10 mt-10 border-t border-gray-200 sm:pt-16 sm:mt-16">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
              >
                <div className="flex-auto lg:col-span-7 xl:col-span-8">
                  <div className="aspect-w-4 aspect-h-5 rounded-lg bg-gray-100 overflow-hidden">
                    <img src={feature.imageSrc} alt={feature.imageAlt} className="object-center object-cover" />
                  </div>
                </div>
                <div className="mt-6 lg:mt-0 lg:col-span-5 xl:col-span-4">
                  <h3 className="text-4xl font-bold text-caffeinity-green-dark">{feature.name}</h3>
                  <p className="mt-2 text-xl text-gray-600 mb-4 text-justify">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <Link
            to="/brewguide"
            className="btn-primary mt-24">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
            </svg>
            {" "}
            Zurück zu allen Brew Guides
          </Link>
        </div>
      </div>
    </div>

    <h1 className="bg-caffeinity-brown text-center py-14">Tipps & Tricks</h1>
    {tippsntricks.map((tipps) => (
      <InfoSection className="bg-caffeinity-pink text-caffeinity-green-dark" textHeading={tipps.title}
                   textSubtitle={tipps.description} />
    ))}


    <CtaGetCoffee />
  </Layout>
)

export default SiebtraegerPage
